import { hmModels } from 'hm-models';
import { 
    AUTH_LOGIN, 
    COMPANY_DELETE, 
    COMPANY_GET, 
    COMPANY_GET_ALL, 
    COMPANY_POST,
    COMPANY_POST_UPLOAD,
    COMPANY_PUT,
    QUIZ_DELETE,
    QUIZ_EXPORT,
    QUIZ_GET_ALL,
    REGION_GET_ALL
} from '../constants/hmConstants'
import request from './basePipeline';

export const authLogin = (payload: hmModels.loginCredentials) => {
    return request.postWithoutToken(AUTH_LOGIN, payload);
}

export const companyGetAll = () => {
    return request.getWithToken(COMPANY_GET_ALL);
}

export const companyGet = (id: string) => {
    return request.getWithToken(COMPANY_GET + id);
}

export const companyPost = (body: any) => {
    return request.postWithToken(COMPANY_POST, body);
}

export const companyPut = (id: number, body: any) => {
    return request.putWithToken(COMPANY_PUT + id, body);
}

export const companyDelete = (ids: any) => {
    const urlSearchParams = new URLSearchParams();
    console.log(ids);
    for(const id of ids) {
        urlSearchParams.append("id", id);
    }
    console.log(urlSearchParams.toString());
    return request.deleteWithToken(COMPANY_DELETE + urlSearchParams.toString());
}

export const companyPostUpload = (file: any) => {
    const formData = new FormData();
    formData.append("excel", file);
    return request.postUploadWithToken(COMPANY_POST_UPLOAD, formData, { 'Content-Type': 'multipart/form-data' });
}

export const regionGetAll = () => {
    return request.getWithToken(REGION_GET_ALL);
}

export const quizGetAll = () => {
    return request.getWithToken(QUIZ_GET_ALL);
}

export const quizExportAll = () => {
    return request.postExportWithToken(QUIZ_EXPORT, {}, { 'responseType' : 'blob' });
}

export const quizDelete = (ids: any) => {
    const urlSearchParams = new URLSearchParams();
    for(const id of ids) {
        urlSearchParams.append("id", id);
    }
    return request.deleteWithToken(QUIZ_DELETE + urlSearchParams.toString());
}