import React, { useEffect } from 'react';
import { AppBar, createStyles, CssBaseline, IconButton, makeStyles, Menu, MenuItem, Theme, Toolbar, Typography } from '@material-ui/core';
import Context from './context';
import DrawerDashboard from './drawer';
import { connect } from 'react-redux';
import MenuBar from 'hm-components/dashboard/menuBar';
import history from '../../utils/history';
import { bindActionCreators, Dispatch } from 'redux';
import { authLogoutRequest } from 'redux/actions/hmActions';
import './dashboard.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        // padding: theme.spacing(3),
        paddingTop: '48px'
    },
  }),
);

const DashboardContainer: React.FunctionComponent = ({children, hmReducers, authLogoutRequest}: any) => {

    const classes = useStyles();
    const [mount, setMount] = React.useState(false);
    const [open, setOpen] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openModal, setOpenModal] = React.useState(false);

    const isMenuOpen = Boolean(anchorEl);

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const onLogon = () => {
        authLogoutRequest();
    }

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={onLogon}>Log off</MenuItem>
        </Menu>
    );

    const handleDrawer = () => {
        setOpen(!open);
    };

    // useEffect(() => {
    //     if(!mount) {
    //         if (hmReducers.currentBussOfic === '') {
    //             setOpenModal(true);
    //             setMount(true);
    //         }
    //     }
    // });

    return (
        <div className="container">
            <CssBaseline />
            <AppBar
                position="fixed"
                className={classes.appBar}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawer}
                        edge="start"
                        className={classes.menuButton}
                    >
                        <i className="fas fa-bars"></i>
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        Hearts & Minds
                    </Typography>
                    <div style={ { flexGrow: 1 } }/>
                    <div>
                        <IconButton
                            edge="end"
                            aria-label="cuenta de usuario"
                            aria-controls={menuId}
                            onClick={handleProfileMenuOpen}
                            aria-haspopup="true"
                            color="inherit"
                            >
                            {/* <AccountCircle /> */}
                            <i className='fas fa-user-circle'></i>
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {renderMenu}
            <DrawerDashboard open={open}/>
            <main className={classes.content}>
                {/* <div className={classes.toolbar} /> */}
                {/* <MenuBar/> */}
                <div className='body__container'>
                  {children}
                </div>
            </main>
            {/* <Context handleClose={handleCloseModal} open={openModal}/> */}
        </div>
    );
}

const hmReducers = 'hmReducers';

const mapStateToProps = (state: any) => ({
    hmReducers: state.get(hmReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        authLogoutRequest,
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);