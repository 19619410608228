import React from 'react';
import { Checkbox } from '@material-ui/core';
import './base.scss';

interface IListView {
    header: Array<any>,
    body: Array<any>,
    selectAction: any
    selectAllAction: any
    total?: number
    currentPage?: number
    onNavigate?: (page: number) => void
}

const ListView = (props: IListView) => {

    // const [select, setSelect] = React.useState<any>({});

    const { header, body } = props;

    const onSelect = (key: number) => {
        props.selectAction(key);
    }

    const onSelectAll = (checked: boolean) => {
        props.selectAllAction(checked);
    }

    const getValueFromNestedKeys = (item: any, text: string) => {
        try {
            const keys = text.split('.');
            let newItem = Object.assign({}, item);
            for (const iterator of keys) {
                newItem = newItem[iterator];
            }
            return newItem;
        }
        catch { }
    }

    return (
        <table className="blueTable">
            <thead>
                <tr>
                    <th className="rownumber header">&nbsp;</th>
                    <th className="checkbox header"><Checkbox inputProps={{ 'aria-label': 'secondary checkbox' }} onClick={(e: any) => onSelectAll(e.target.checked)}/></th>
                    {
                        header.map((item, key) => 
                            <th key={`header_listview_${key}`}>{item.title}</th>
                        )
                    }
                </tr>
            </thead>
            <tfoot>
                <tr>
                    <td colSpan={header.length + 2}>
                        <div className="links">
                            {   
                                props.currentPage - 1 > 0 &&
                                <>
                                    <a href="#" onClick={() => props.onNavigate(props.currentPage - 1)}>&laquo;</a> 
                                    <a href="#" onClick={() => props.onNavigate(props.currentPage - 1)}>{props.currentPage - 1}</a> 
                                </>
                            }
                            <a className="active" href="#">{props.currentPage}</a> 
                            {
                                props.currentPage + 1 <= +(props.total/20).toFixed(0) &&
                                <>
                                    <a href="#" onClick={() => props.onNavigate(props.currentPage + 1)}>{props.currentPage + 1}</a>
                                    <a href="#" onClick={() => props.onNavigate(props.currentPage + 1)}>&raquo;</a>
                                </>
                            } 
                        </div>
                    </td>
                </tr>
            </tfoot>
            <tbody>
                {
                    body.map((item, key) => 
                        <tr key={`body_listview_${key}`}>
                            <td className="rownumber">{key + 1}</td>
                            <td className="checkbox">
                                <Checkbox
                                    color="primary"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    onClick={() => onSelect(key)}
                                    checked={item.select === undefined ? false : item.select}
                                />
                            </td>
                            {
                                header.map((hitem, key) => 
                                    <td style={typeof(hitem.style) == "function" ? hitem.style(getValueFromNestedKeys, item) : {}} key={`${hitem.key}_${key}`}>
                                        {
                                            typeof(hitem.value) === "function" &&
                                            hitem.value(getValueFromNestedKeys, item)
                                            ||
                                            getValueFromNestedKeys(item, hitem.key)
                                        }
                                    </td>
                                )
                            }
                        </tr>
                    )
                }
            </tbody>
        </table>
    )
};

export default ListView;