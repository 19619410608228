import { 
    CASH_BANKS_ACTION_FAILED,
    CASH_BANKS_DELETE_REQUEST,
    CASH_BANKS_DELETE_REQUEST_FAILED,
    CASH_BANKS_DELETE_REQUEST_SUCCESS,
    CASH_BANKS_GET_ALL_RECORDS_REQUEST,
    CASH_BANKS_GET_ALL_RECORDS_REQUEST_FAILED,
    CASH_BANKS_GET_ALL_RECORDS_REQUEST_SUCCESS,
    CASH_BANKS_INIT_FORM_REQUEST, 
    CASH_BANKS_INIT_FORM_REQUEST_FAILED, 
    CASH_BANKS_INIT_FORM_REQUEST_SUCCESS, 
    CASH_BANKS_INIT_UPDATE_FORM_REQUEST, 
    CASH_BANKS_INIT_UPDATE_FORM_REQUEST_FAILED, 
    CASH_BANKS_INIT_UPDATE_FORM_REQUEST_SUCCESS, 
    CASH_BANKS_INSERT_REQUEST, 
    CASH_BANKS_INSERT_REQUEST_FAILED, 
    CASH_BANKS_INSERT_REQUEST_SUCCESS, 
    CASH_BANKS_LIST_SELECT_ALL_ITEM, 
    CASH_BANKS_LIST_SELECT_ITEM, 
    CASH_BANKS_MOVEMENTS_ACTION_FAILED, 
    CASH_BANKS_MOVEMENTS_DELETE_REQUEST, 
    CASH_BANKS_MOVEMENTS_DELETE_REQUEST_FAILED, 
    CASH_BANKS_MOVEMENTS_DELETE_REQUEST_SUCCESS, 
    CASH_BANKS_MOVEMENTS_GET_ALL_RECORDS_REQUEST, 
    CASH_BANKS_MOVEMENTS_GET_ALL_RECORDS_REQUEST_FAILED, 
    CASH_BANKS_MOVEMENTS_GET_ALL_RECORDS_REQUEST_SUCCESS, 
    CASH_BANKS_MOVEMENTS_INIT_FORM_REQUEST, 
    CASH_BANKS_MOVEMENTS_INIT_FORM_REQUEST_FAILED, 
    CASH_BANKS_MOVEMENTS_INIT_FORM_REQUEST_SUCCESS,
    CASH_BANKS_MOVEMENTS_INIT_UPDATE_FORM_REQUEST,
    CASH_BANKS_MOVEMENTS_INIT_UPDATE_FORM_REQUEST_FAILED,
    CASH_BANKS_MOVEMENTS_INIT_UPDATE_FORM_REQUEST_SUCCESS,
    CASH_BANKS_MOVEMENTS_INSERT_REQUEST,
    CASH_BANKS_MOVEMENTS_INSERT_REQUEST_FAILED,
    CASH_BANKS_MOVEMENTS_INSERT_REQUEST_SUCCESS,
    CASH_BANKS_MOVEMENTS_LIST_SELECT_ALL_ITEM,
    CASH_BANKS_MOVEMENTS_LIST_SELECT_ITEM,
    CASH_BANKS_MOVEMENTS_UPDATE_REQUEST,
    CASH_BANKS_MOVEMENTS_UPDATE_REQUEST_FAILED,
    CASH_BANKS_MOVEMENTS_UPDATE_REQUEST_SUCCESS,
    CASH_BANKS_UPDATE_REQUEST,
    CASH_BANKS_UPDATE_REQUEST_SUCCESS,
    CHECKS_ACTION_FAILED,
    CHECKS_DELETE_REQUEST,
    CHECKS_DELETE_REQUEST_FAILED,
    CHECKS_DELETE_REQUEST_SUCCESS,
    CHECKS_GET_ALL_RECORDS_REQUEST,
    CHECKS_GET_ALL_RECORDS_REQUEST_FAILED,
    CHECKS_GET_ALL_RECORDS_REQUEST_SUCCESS,
    CHECKS_INIT_FORM_REQUEST,
    CHECKS_INIT_FORM_REQUEST_FAILED,
    CHECKS_INIT_FORM_REQUEST_SUCCESS,
    CHECKS_INIT_UPDATE_FORM_REQUEST,
    CHECKS_INIT_UPDATE_FORM_REQUEST_FAILED,
    CHECKS_INIT_UPDATE_FORM_REQUEST_SUCCESS,
    CHECKS_INSERT_REQUEST,
    CHECKS_INSERT_REQUEST_FAILED,
    CHECKS_INSERT_REQUEST_SUCCESS,
    CHECKS_LIST_SELECT_ALL_ITEM,
    CHECKS_LIST_SELECT_ITEM,
    CHECKS_UPDATE_REQUEST,
    CHECKS_UPDATE_REQUEST_FAILED,
    CHECKS_UPDATE_REQUEST_SUCCESS
} from '../constants/financeConstants';

const initialState: any = {
    tables: { 
        planAccount: [], 
        grl_definiti: [], 
        acc_planacco: [],
        pbl_supplier: [],
        pbl_purchase: [],
        csb_cashbank: []
    },
    onlyOne: null,
    list: [],
    total: 0,
    loading: false,
    failed: false,
    failedTitle: null,
    failedContent: null
};

export default function financeReducer(state = initialState, action: any = {}) {
    switch(action.type) {
        case CASH_BANKS_INIT_FORM_REQUEST:
        case CASH_BANKS_MOVEMENTS_INIT_FORM_REQUEST:
        case CHECKS_INIT_FORM_REQUEST:
            return {
                ...state,
                loading: true
            };
        case CASH_BANKS_INIT_FORM_REQUEST_SUCCESS:
        case CASH_BANKS_MOVEMENTS_INIT_FORM_REQUEST_SUCCESS:
        case CHECKS_INIT_FORM_REQUEST_SUCCESS:
            return {
                ...state,
                tables: { ...state.tables, ...action.tables },
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case CASH_BANKS_INIT_FORM_REQUEST_FAILED:
        case CASH_BANKS_MOVEMENTS_INIT_FORM_REQUEST_FAILED:
        case CHECKS_INIT_FORM_REQUEST_FAILED:
            return {
                ...initialState,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case CASH_BANKS_INIT_UPDATE_FORM_REQUEST:
        case CASH_BANKS_MOVEMENTS_INIT_UPDATE_FORM_REQUEST:
        case CHECKS_INIT_UPDATE_FORM_REQUEST:
            return {
                ...state,
                loading: true
            };
        case CASH_BANKS_INIT_UPDATE_FORM_REQUEST_SUCCESS:
        case CASH_BANKS_MOVEMENTS_INIT_UPDATE_FORM_REQUEST_SUCCESS:
        case CHECKS_INIT_UPDATE_FORM_REQUEST_SUCCESS:
            return {
                ...state,
                tables: { ...state.tables, ...action.tables },
                onlyOne: action.onlyOne,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case CASH_BANKS_INIT_UPDATE_FORM_REQUEST_FAILED:
        case CASH_BANKS_MOVEMENTS_INIT_UPDATE_FORM_REQUEST_FAILED:
        case CHECKS_INIT_UPDATE_FORM_REQUEST_FAILED:
            return {
                ...initialState,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case CASH_BANKS_GET_ALL_RECORDS_REQUEST:
        case CASH_BANKS_MOVEMENTS_GET_ALL_RECORDS_REQUEST:
        case CHECKS_GET_ALL_RECORDS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case CASH_BANKS_GET_ALL_RECORDS_REQUEST_SUCCESS:
        case CASH_BANKS_MOVEMENTS_GET_ALL_RECORDS_REQUEST_SUCCESS:
        case CHECKS_GET_ALL_RECORDS_REQUEST_SUCCESS:
            return {
                ...state,
                list: action.list,
                total: action.total,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case CASH_BANKS_GET_ALL_RECORDS_REQUEST_FAILED:
        case CASH_BANKS_MOVEMENTS_GET_ALL_RECORDS_REQUEST_FAILED:
        case CHECKS_GET_ALL_RECORDS_REQUEST_FAILED:
            return {
                ...initialState,
                list: [],
                total: 0,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case CASH_BANKS_INSERT_REQUEST:
        case CASH_BANKS_MOVEMENTS_INSERT_REQUEST:
        case CHECKS_INSERT_REQUEST:
            return {
                ...state,
                loading: true
            };
        case CASH_BANKS_INSERT_REQUEST_SUCCESS:
        case CASH_BANKS_MOVEMENTS_INSERT_REQUEST_SUCCESS:
        case CHECKS_INSERT_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case CASH_BANKS_INSERT_REQUEST_FAILED:
        case CASH_BANKS_MOVEMENTS_INSERT_REQUEST_FAILED:
        case CHECKS_INSERT_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case CASH_BANKS_UPDATE_REQUEST:
        case CASH_BANKS_MOVEMENTS_UPDATE_REQUEST:
        case CHECKS_UPDATE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case CASH_BANKS_UPDATE_REQUEST_SUCCESS:
        case CASH_BANKS_MOVEMENTS_UPDATE_REQUEST_SUCCESS:
        case CHECKS_UPDATE_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case CASH_BANKS_INSERT_REQUEST_FAILED:
        case CASH_BANKS_MOVEMENTS_UPDATE_REQUEST_FAILED:
        case CHECKS_UPDATE_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case CASH_BANKS_DELETE_REQUEST:
        case CASH_BANKS_MOVEMENTS_DELETE_REQUEST:
        case CHECKS_DELETE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case CASH_BANKS_DELETE_REQUEST_SUCCESS:
        case CASH_BANKS_MOVEMENTS_DELETE_REQUEST:
        case CHECKS_DELETE_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case CASH_BANKS_DELETE_REQUEST_FAILED:
        case CASH_BANKS_MOVEMENTS_DELETE_REQUEST:
        case CHECKS_DELETE_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case CASH_BANKS_LIST_SELECT_ITEM:
        case CASH_BANKS_MOVEMENTS_LIST_SELECT_ITEM:
        case CHECKS_LIST_SELECT_ITEM:
            const newList = state.list;
            newList[action.key].select = !newList[action.key].select;
            return {
                ...state,
                list: newList
            };
        case CASH_BANKS_LIST_SELECT_ALL_ITEM:
        case CASH_BANKS_MOVEMENTS_LIST_SELECT_ALL_ITEM:
        case CHECKS_LIST_SELECT_ALL_ITEM:
            const newListAll = state.list;
            for (const item of newListAll) {
                item.select = action.checked
            }
            return {
                ...state,
                list: newListAll
            };
        case CASH_BANKS_ACTION_FAILED:
        case CASH_BANKS_MOVEMENTS_ACTION_FAILED:
        case CHECKS_ACTION_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        default:
            return state;
    }
}