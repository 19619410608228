import React from 'react';
import { Backdrop, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, Snackbar, TextField, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { companyGetRequest, companyPostRequest, companyPutRequest, regionGetAllRequest } from 'hm-redux/actions/hmActions';
// import { locationsGetProvincesRequest, locationsGetDistrictsRequest } from 'hm-redux/actions/generalActions';
import { company } from 'models/hmModels';
import history from '../../../utils/history';
import './company.scss'
import FormFooter from 'hm-components/base/formFooter';
import { MessageBox } from 'hm-components/base/messageBox';
import { Alert, Autocomplete } from '@material-ui/lab';
import CustomizedHook from 'hm-components/base/customizedHook';
import EditableCell from 'hm-components/base/editableCell';

const header = ["", "Country", "Sites"];

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

const b64toBlob = (b64Data, sliceSize=512) => {
    const byteCharacters = atob(b64Data.split(',')[1]);
    const byteArrays = [];
    const contentType = b64Data.split(',')[0].split(';')[0].split(':')[1];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
    
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
        }
    
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
}

const defaultCompany: company = {
    id: '',
    logo: '',
    name: '',
    quizurl: '',
    region: [],
    branch: []
}

interface FormCompanyState {
    company: company
    title: string
    currentFile: any
    previewImage: any
    snackbar: boolean
}

class FormUsers extends React.Component<any, FormCompanyState> {

    constructor(props: any) {
        super(props);
        defaultCompany.region = [{
            id: '',
            code: '',
            name: null,
            site: ''
        }];
        this.state = {
            company: defaultCompany,
            title: 'Add Company',
            currentFile: null,
            previewImage: '',
            snackbar: false
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onChangeDepartment = (value: any) => {
        this.onChangeRoot(value);
        this.props.locationsGetProvincesRequest(value.department);
    }

    onChangeProvince = (value: any) => {
        this.onChangeRoot(value);
        this.props.locationsGetDistrictsRequest(value.province);
    }

    onChangeCompanyRoot = (value: any) => {
        console.log(value);
        this.setState((state) => ({
            ...state,
            company: {
                ...state.company,
                ...value
            }
        }))
    }

    onChangeCompanyRegion = (value: any) => {
        this.setState((state) => ({
            ...state,
            company: {
                ...state.company,
                region: {
                    ...state.company.region,
                    ...value
                }
            }
        }))
    }

    upload = (e: any) => {
        this.setState({
            currentFile: e.target.files[0],
            previewImage: URL.createObjectURL(e.target.files[0]),
        });
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]); 
        reader.onloadend = () => {
            var base64data = reader.result;           
            this.onChangeCompanyRoot({'logo': base64data});
        }
    }

    onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        history.push('/admin/company');
    }

    onContinueCallback = () => {
        defaultCompany.region = [{
            id: '',
            code: '',
            name: null,
            site: ''
        }];
        const newCompany = defaultCompany;
        this.setState({
            company: newCompany,
            currentFile: null,
            previewImage: '',
            snackbar: true
        });
    }

    onSubmitContinue = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        const company = this.onConverteSite();
        if (params.id !== undefined) {
            this.props.companyPutRequest(company, null);
        } else {
            this.props.companyPostRequest(company, this.onContinueCallback);
        }
    }

    onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        const company = this.onConverteSite();
        if (params.id !== undefined) {
            this.props.companyPutRequest(company, null);
        } else {
            this.props.companyPostRequest(company, null);
        }
    }

    onConverteSite = () => {
        const company = this.state.company;
        const region = company.region.filter((x: any) => x.code !== '');
        company.branch = [];
        for (const iterator of region) {
            const sites = iterator.site.split(',');
            for (const _iterator of sites) {
                company.branch.push({codeRegion: iterator.code, name: _iterator.trim()})
            }
        }
        company.region = region;
        return company;
    }

    onEditCell = (props: any, value: any) => {
        const region: any = this.state.company.region;
        region[props.index][props.field] = value;

        this.setState((state: FormCompanyState) => ({
            ...state,
            company: {
                ...state.company,
                region,
            }
        }));
    }

    onAddDetail = () => {
        const { region } = this.state.company;
        const newItem: any = {
            id: '',
            code: '',
            name: null,
            site: ''
        };
        region.push(newItem);
        this.setState((state: FormCompanyState) => ({
            ...state,
            company: {
                ...state.company,
                region,
            }
        }))
    }

    onDeleteDetail = () => {
        let { region } = this.state.company;   
        region = region.filter((x: any) => x.select === false || x.select === undefined);

        this.setState((state: any) => ({
            ...state,
            company: {
                ...state.company,
                region,
            }
        }));
    }

    renderDetail = () => {
        const { tables } = this.props.hmReducers;
        const { region } = this.state.company;
        
        return region.map((item: any, i: any) => (
            <tr key={ i } >
                <td>
                    <input type="checkbox"
                    checked={ item.select } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'select' }, 
                            e.target.checked
                        )}
                    />
                </td>
                <td style={{width:'300px'}}>
                    <Autocomplete
                        id="region"
                        freeSolo
                        options={tables.region}
                        getOptionLabel={ (x: any) => x.name }
                        value={ tables.region.find((x: any) => x.name === item.name) || null }
                        onChange={(e: any, value) => {
                            this.onEditCell({index: i, field: 'id'}, value.id);
                            this.onEditCell({index: i, field: 'code'}, value.code);
                            this.onEditCell({index: i, field: 'name'}, value.name);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} 
                            style={ { marginTop: 0 } } margin="normal" variant="outlined" />
                        )}
                    />
                </td>
                <td style={{width:'300px'}}>
                    <input type="text"
                    value={ item.site } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'site' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'left' }}
                    />
                </td>
            </tr>
        ))
    }

    renderGrilla = () => {
        return (
            <div className="form">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className="form__field">
                            <div className="grid__actions">
                                <button className="add" onClick={ (e) => { e.preventDefault(); this.onAddDetail() } }><i className="fas fa-plus"/></button>
                                <button className="remove" onClick={ (e) => { e.preventDefault(); this.onDeleteDetail() } }><i className="fas fa-times"/></button>
                                <span style={ { marginLeft: '15px' } }>[ENTER]: Add new item</span>
                                <span style={ { marginLeft: '15px' } }>[TAB]: Navigate</span>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <EditableCell
                            header={ header }
                            data={ this.renderDetail() }
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }

    callbackInitUpdate = (company: company) => {
        let previewImage = ""
        try { previewImage = URL.createObjectURL(b64toBlob(company.logo)); } catch { };
        for (const iterator of company.region) {
            iterator.site = company.branch.filter((x: any) => x.codeRegion === iterator.code).map((x: any) => x.name).join(',');
        }
        this.setState({
            company,
            currentFile: null,
            previewImage
        });
    }

    componentDidMount() {
        const { params } = this.props.match;
        if (params.id !== undefined) {
            this.setState({title: 'Edit Company'});
            this.props.companyGetRequest(params.id, this.callbackInitUpdate);
        }
        else {
            this.props.regionGetAllRequest();
        }
    }

    render() {

        const { company, title, snackbar, currentFile, previewImage } = this.state;
        const { loading, tables, failed, failedTitle, failedContent } = this.props.hmReducers;
        // const generalTables = this.props.generalReducers.tables;
        const { classes } = this.props;

        return (
            <div>
                <Paper className='form__container'>
                    <br/>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    <br/>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <form noValidate autoComplete="off">
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <TextField fullWidth id="name" label="name" variant="outlined" value={ company.name } onChange={(e: any) => this.onChangeCompanyRoot({'name': e.target.value})}/>
                            </Grid>
                            <Grid item xs={3}>
                                {/* {previewImage && (
                                    <div>
                                        <img className="preview my20" src={previewImage} alt="" />
                                    </div>
                                )} */}
                            </Grid>
                        </Grid>
                        <div className="seccion__title">LOGO</div>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <input
                                    accept="image/*"
                                    style={{display: 'none'}}
                                    id="contained-button-file"
                                    multiple
                                    type="file"
                                    onChange={this.upload}
                                />
                                <label htmlFor="contained-button-file">
                                    <Button variant="contained" color="primary" component="span">
                                    Upload Image
                                    </Button>
                                </label>
                            </Grid>
                        </Grid>
                        <div style={{ height: 50 }}/>
                        <Grid container spacing={2}>
                            { currentFile ? currentFile.name : '' }
                            <Grid item xs={6}>
                                {previewImage && (
                                    <div>
                                        <img width="250" height="250" src={previewImage} alt="" />
                                    </div>
                                ) || (
                                    <div>
                                        <img width="250" height="250" src={'https://png.pngtree.com/png-vector/20191129/ourmid/pngtree-image-upload-icon-photo-upload-icon-png-image_2047545.jpg'} alt=""/>
                                    </div>
                                )
                                }
                            </Grid>
                        </Grid>
                        <div className="seccion__title">COUNTRIES AND SITES</div>
                        <Grid container spacing={2} >
                            { this.renderGrilla()  }
                        </Grid>
                        <div style={{ height: 50 }}/>
                        {/* <Grid container spacing={2}>
                            <CustomizedHook 
                                title="Sites" 
                                list={branchs} 
                                onValue={ company.branch }
                                onChange={(e: any, value) => this.onChangeCompanyRoot({'branch': value})}
                            />
                        </Grid> */}
                    </form>
                    <br/>
                    <FormFooter 
                        onCancel={ this.onCancel } 
                        onSubmitContinue={ this.onSubmitContinue } 
                        onSubmit={ this.onSubmit }/>
                </Paper>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={snackbar}
                    onClose={() => this.setState({snackbar: false})}
                    autoHideDuration={6000}
                >
                    <Alert onClose={() => this.setState({snackbar: false})} severity="success">
                        Data Saved Correctly
                    </Alert>
                </Snackbar>
                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        );
    }
}

const hmReducers = 'hmReducers';

const mapStateToProps = (state: any) => ({
    hmReducers: state.get(hmReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        companyGetRequest,
        // companyInitFormRequest,
        // companyInitUpdateFormRequest,
        // locationsGetProvincesRequest,
        // locationsGetDistrictsRequest,
        companyPostRequest,
        companyPutRequest,
        regionGetAllRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(FormUsers));