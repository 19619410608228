const serverUrl = process.env.HM_MASTER_SERVER_URL;

export const AUTH_LOGIN = serverUrl + 'auth/login';

export const COMPANY_GET_ALL = serverUrl + 'company/all';
export const COMPANY_GET = serverUrl + 'company/';
export const COMPANY_POST = serverUrl + 'company';
export const COMPANY_PUT = serverUrl + 'company/';
export const COMPANY_DELETE = serverUrl + 'company?';
export const COMPANY_POST_UPLOAD = serverUrl + 'company/upload';

export const REGION_GET_ALL = serverUrl + 'region/all';

export const QUIZ_GET_ALL = serverUrl + 'quiz/all';
export const QUIZ_EXPORT = serverUrl + 'quiz/export';
export const QUIZ_DELETE = serverUrl + 'quiz?';