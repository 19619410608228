import {
    call, fork, put, take, takeEvery, all
  } from 'redux-saga/effects';
import history from '../../utils/history';
import { AUTH_LOGIN_REQUEST, AUTH_LOGOUT_REQUEST, COMPANY_DELETE_REQUEST, COMPANY_GET_ALL_REQUEST, COMPANY_GET_REQUEST, COMPANY_POST_REQUEST, COMPANY_POST_UPLOAD_REQUEST, COMPANY_PUT_REQUEST, QUIZ_DELETE_REQUEST, QUIZ_EXPORT_REQUEST, QUIZ_GET_ALL_REQUEST, REGION_GET_ALL_REQUEST } from 'redux/constants/hmConstants';
import pipelines from 'services';
import { 
    authLoginRequestFailed, 
    authLoginRequestSuccess, 
    companyDeleteRequestFailed, 
    companyGetAllRequestFailed, 
    companyGetAllRequestSuccess, 
    companyGetRequestFailed, 
    companyGetRequestSuccess, 
    companyPostRequestFailed, 
    companyPostRequestSuccess, 
    companyPostUploadRequestFailed, 
    companyPostUploadRequestSuccess, 
    companyPutRequestFailed, 
    dashboardReset,
    quizDeleteRequestFailed,
    quizExportRequestFailed,
    quizExportRequestSuccess,
    quizGetAllRequestFailed,
    quizGetAllRequestSuccess,
    regionGetAllRequestFailed,
    regionGetAllRequestSuccess
} from 'redux/actions/hmActions';

function* loginSaga(action: any) {
    try {
        const data = (yield call(pipelines.hmPipeline.authLogin, action.payload)).data;
        localStorage.setItem('user', JSON.stringify(data.data.onlyOne));
        localStorage.setItem('token', data.data.onlyOne.usrtoken);
        localStorage.setItem('tables', JSON.stringify(data.data.tables));
        yield put(authLoginRequestSuccess());
        yield history.push('/');
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(authLoginRequestFailed(`Error: ${err.code}`, err.message));
    }
}

function* logoutSaga(action: any) {
    try {
        localStorage.removeItem('token');
        yield put(dashboardReset());
        yield history.push('/login');
    } catch(error) {
        console.log(error);
    }
}

function* companyGetAllSaga(action: any) {
    try {
        const data = (yield call(pipelines.hmPipeline.companyGetAll)).data;
        yield put(companyGetAllRequestSuccess(data.data.list, data.data.total));
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(companyGetAllRequestFailed(`Error: ${err.code}`, err.message));
    }
}

function* companyGetSaga(action: any) {
    try {
        const data = (yield call(pipelines.hmPipeline.companyGet, action.id)).data;
        yield put(companyGetRequestSuccess(data.data.tables, data.data.onlyOne, data.data.tables));
        yield call(action.callback, data.data.onlyOne);
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(companyGetRequestFailed(`Error: ${err.code}`, err.message));
    }
}

function* companyPostSaga(action: any) {
    try {
        yield call(pipelines.hmPipeline.companyPost, action.body);
        if (action.callback !== null) {
            yield call(action.callback);
            yield put(companyPostRequestSuccess());
        }
        else
            yield history.push('/admin/company');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(companyPostRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* companyPutSaga(action: any) {
    try {
        const data = (yield call(pipelines.hmPipeline.companyPut, action.body.id, action.body)).data;
        if (action.callback !== null) {
            yield history.push('/admin/company/add');
        }
        else
            yield history.push('/admin/company');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(companyPutRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* companyDeleteSaga(action: any) {
    try {
        const data = (yield call(pipelines.hmPipeline.companyDelete, action.ids)).data;
        yield put({ type: COMPANY_GET_ALL_REQUEST, body: {} });
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(companyDeleteRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* companyPostUploadSaga(action: any) {
    try {
        const data = (yield call(pipelines.hmPipeline.companyPostUpload, action.file)).data;
        yield put({ type: COMPANY_GET_ALL_REQUEST, body: {} });
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(companyPostUploadRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* regionGetAllSaga(action: any) {
    try {
        const data = (yield call(pipelines.hmPipeline.regionGetAll)).data;
        yield put(regionGetAllRequestSuccess(data.data.tables));
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(regionGetAllRequestFailed(`Error: ${err.code}`, err.message));
    }
}

function* quizGetAllSaga(action: any) {
    try {
        const data = (yield call(pipelines.hmPipeline.quizGetAll)).data;
        yield put(quizGetAllRequestSuccess(data.data.list, data.data.total));
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(quizGetAllRequestFailed(`Error: ${err.code}`, err.message));
    }
}

function* quizExportSaga(action: any) {
    try {
        var fileDownload = require('js-file-download');
        const data = (yield call(pipelines.hmPipeline.quizExportAll)).data;
        fileDownload(data, 'responses.csv');
        yield put(quizExportRequestSuccess());
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(quizExportRequestFailed(`Error: ${err.code}`, err.message));
    }
}

function* quizDeleteSaga(action: any) {
    try {
        const data = (yield call(pipelines.hmPipeline.quizDelete, action.ids)).data;
        yield put({ type: QUIZ_GET_ALL_REQUEST, body: {} });
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(quizDeleteRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* hmRootSaga() {
    yield all([
        takeEvery(AUTH_LOGIN_REQUEST, loginSaga),
        takeEvery(AUTH_LOGOUT_REQUEST, logoutSaga),
        takeEvery(COMPANY_GET_ALL_REQUEST, companyGetAllSaga),
        takeEvery(COMPANY_GET_REQUEST, companyGetSaga),
        takeEvery(COMPANY_POST_REQUEST, companyPostSaga),
        takeEvery(COMPANY_PUT_REQUEST, companyPutSaga),
        takeEvery(COMPANY_DELETE_REQUEST, companyDeleteSaga),
        takeEvery(COMPANY_POST_UPLOAD_REQUEST, companyPostUploadSaga),
        takeEvery(REGION_GET_ALL_REQUEST, regionGetAllSaga),
        takeEvery(QUIZ_GET_ALL_REQUEST, quizGetAllSaga),
        takeEvery(QUIZ_EXPORT_REQUEST, quizExportSaga),
        takeEvery(QUIZ_DELETE_REQUEST, quizDeleteSaga)
    ]);
}
  
const hmSagas = [
    fork(hmRootSaga),
];
  
export default hmSagas;