import * as React from "react";
import { Redirect, Route } from "react-router";
import { JwtVerify } from "utils/jwtVerify";

export const PrivateRoute = ({ component: Component, ...rest }: any) => (
    <Route
      {...rest}
      render={props => 
        JwtVerify() ?
          ( <Component {...props} /> )
        :  (
                <Redirect
                  to={{
                    pathname: "/login",
                    state: { from: props.location }
                  }}
                />
            ) 
      }
    />
);