import { Backdrop, CircularProgress, createStyles, Theme, withStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import './dashboard.scss';

const useStyles = (theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  });


class Dashboard extends React.Component<any> {

    render() {

        const { loading } = this.props.hmReducers;
        const { classes } = this.props;

        return (
            <div className="dashboard__container">
                
                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        );
    }
}

const reducer = 'hmReducers';

const mapStateToProps = (state: any) => ({
  hmReducers: state.get(reducer)
});

// const mapDispatchToProps = (dispatch: Dispatch) => {
//     return bindActionCreators({
//         authLoginRequest,
//     }, dispatch);
// };  

export default connect(mapStateToProps, null)(withStyles(useStyles)(Dashboard));