import React, { useEffect } from 'react';
import { Button, createStyles, Dialog, FormControl, IconButton, InputLabel, makeStyles, MenuItem, Select, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
// import { dashboardSetWorkerContext } from 'redux/actions/dashboardActions';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        }
    });

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    })
);

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <i className="fas fa-times"></i>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ActionDialog = ({ handleClose, open, authReducers, dashboardReducers, dashboardSetWorkerContext, onSubmit }: any) => {

    const classes = useStyles();

    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                List
            </DialogTitle>
            <DialogContent dividers>
                <Typography variant='subtitle1'>Are you sure to delete the elements?</Typography>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={onSubmit} color="primary">
                    Delete
                </Button>
            </DialogActions>
      </Dialog>
    )
}

const authReducers = 'authReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    authReducers: state.get(authReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        // dashboardSetWorkerContext,
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(ActionDialog);