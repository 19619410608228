import { hmModels } from 'hm-models';
import * as types from '../constants/hmConstants';

export const authLoginRequest = (payload: hmModels.loginCredentials) => ({
    type: types.AUTH_LOGIN_REQUEST,
    payload
});

export const authLoginRequestSuccess = () => ({
    type: types.AUTH_LOGIN_REQUEST_SUCCESS
});

export const authLoginRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.AUTH_LOGIN_REQUEST_FAILED,
    titleError,
    titleContent
});

export const authLogoutRequest = () => ({
    type: types.AUTH_LOGOUT_REQUEST
});

export const dashboardSelectMenu = (moducode: string) => ({
    type: types.DASHBOARD_SELECT_MENU,
    moducode
});

export const dashboardReset = () => ({
    type: types.DASHBOARD_RESET
});

export const companyGetAllRequest = () => ({
    type: types.COMPANY_GET_ALL_REQUEST
});

export const companyGetAllRequestSuccess = (list: Array<any>, total: number) => ({
    type: types.COMPANY_GET_ALL_REQUEST_SUCCESS,
    list,
    total
});

export const companyGetAllRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.COMPANY_GET_ALL_REQUEST_FAILED,
    titleError,
    titleContent
});

export const companyGetRequest = (id: string, callback: any) => ({
    type: types.COMPANY_GET_REQUEST,
    callback,
    id
});

export const companyGetRequestSuccess = (list: Array<any>, total: number, tables: any) => ({
    type: types.COMPANY_GET_REQUEST_SUCCESS,
    list,
    total,
    tables
});

export const companyGetRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.COMPANY_GET_REQUEST_FAILED,
    titleError,
    titleContent
});

export const companyPostRequest = (body: any, callback?: void) => ({
    type: types.COMPANY_POST_REQUEST,
    body,
    callback
});

export const companyPostRequestSuccess = () => ({
    type: types.COMPANY_POST_REQUEST_SUCCESS
});

export const companyPostRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.COMPANY_POST_REQUEST_FAILED,
    titleError,
    titleContent
});

export const companyPutRequest = (body: any, callback?: void) => ({
    type: types.COMPANY_PUT_REQUEST,
    body,
    callback
});

export const companyPutRequestSuccess = () => ({
    type: types.COMPANY_PUT_REQUEST_SUCCESS
});

export const companyPutRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.COMPANY_PUT_REQUEST_FAILED,
    titleError,
    titleContent
});

export const companyDeleteRequest = (ids: Array<number>) => ({
    type: types.COMPANY_DELETE_REQUEST,
    ids
});

export const companyDeleteRequestSuccess = () => ({
    type: types.COMPANY_DELETE_REQUEST_SUCCESS
});

export const companyDeleteRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.COMPANY_DELETE_REQUEST_FAILED,
    titleError,
    titleContent
});

export const companyPostUploadRequest = (file: any) => ({
    type: types.COMPANY_POST_UPLOAD_REQUEST,
    file
});

export const companyPostUploadRequestSuccess = () => ({
    type: types.COMPANY_POST_UPLOAD_REQUEST_SUCCESS
});

export const companyPostUploadRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.COMPANY_POST_UPLOAD_REQUEST_FAILED,
    titleError,
    titleContent
});

/*Components */
export const companyListSelectItem = (key: number) => ({
    type: types.COMPANY_LIST_SELECT_ITEM,
    key
});

export const companyListSelectAllItem = (checked: boolean) => ({
    type: types.COMPANY_LIST_SELECT_ALL_ITEM,
    checked
});
export const companyActionFailed = (titleError: string, titleContent: string) => ({
    type: types.COMPANY_ACTION_FAILED,
    titleError,
    titleContent
});

/* */

export const regionGetAllRequest = () => ({
    type: types.REGION_GET_ALL_REQUEST
});

export const regionGetAllRequestSuccess = (tables: any) => ({
    type: types.REGION_GET_ALL_REQUEST_SUCCESS,
    tables
});

export const regionGetAllRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.REGION_GET_ALL_REQUEST_FAILED,
    titleError,
    titleContent
});

export const quizGetAllRequest = () => ({
    type: types.QUIZ_GET_ALL_REQUEST
});

export const quizGetAllRequestSuccess = (list: Array<any>, total: number) => ({
    type: types.QUIZ_GET_ALL_REQUEST_SUCCESS,
    list,
    total
});

export const quizGetAllRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.QUIZ_GET_ALL_REQUEST_FAILED,
    titleError,
    titleContent
});

export const quizExportRequest = () => ({
    type: types.QUIZ_EXPORT_REQUEST
});

export const quizExportRequestSuccess = () => ({
    type: types.QUIZ_EXPORT_REQUEST_SUCCESS
});

export const quizExportRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.QUIZ_EXPORT_REQUEST_FAILED,
    titleError,
    titleContent
});

export const quizDeleteRequest = (ids: Array<number>) => ({
    type: types.QUIZ_DELETE_REQUEST,
    ids
});

export const quizDeleteRequestSuccess = () => ({
    type: types.QUIZ_DELETE_REQUEST_SUCCESS
});

export const quizDeleteRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.QUIZ_DELETE_REQUEST_FAILED,
    titleError,
    titleContent
});

/*Components */
export const quizListSelectItem = (key: number) => ({
    type: types.QUIZ_LIST_SELECT_ITEM,
    key
});

export const quizListSelectAllItem = (checked: boolean) => ({
    type: types.QUIZ_LIST_SELECT_ALL_ITEM,
    checked
});
export const quizActionFailed = (titleError: string, titleContent: string) => ({
    type: types.QUIZ_ACTION_FAILED,
    titleError,
    titleContent
});