import jwtDecode from 'jwt-decode';

export const JwtVerify = () => {
    const token = localStorage.getItem('token');
    console.log(token);
    if (token) {
      const payload = JSON.stringify(jwtDecode(token));
      const exp = JSON.parse(payload).exp;
      if (exp < Date.now() / 1000) {
        localStorage.removeItem(token);
        return false;
      }
      return true;
    }
    return false;
}