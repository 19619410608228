import React from 'react';
import DashboardContainer from 'hm-components/dashboard/dashboardContainer';
import './templates.scss';
import { connect } from 'react-redux';

class Dashboard extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            company: null,
            division: null,
            oficina: null
        }
    }

    componentDidMount() {
        try {
            const { dashboardReducers } = this.props;
            const tables = JSON.parse(localStorage.getItem('tables'));
            const company = tables.companys.find((x: any) => x.taxident === dashboardReducers.currentCompany);
            const division = company.lst_grl_division.find((x: any) => x.divicode === dashboardReducers.currentDivision);
            const oficina = division.lst_grl_bussofic.find((x: any) => x.officode === dashboardReducers.currentBussOfic);
            this.setState({company, division, oficina});
        }
        catch {}
    }

    render() {

        const { company, division, oficina } = this.state;

        return (
            <div className="dashboard__main">
                <DashboardContainer>
                    { this.props.children }
                </DashboardContainer>
                <div className="footer">
                    {
                        company &&
                        <p><b>contexto: </b> {company.bussname + ' - ' + division.diviname + ' - ' + oficina.offiname}</p>
                    }
                </div>
            </div>
        )
    }
};

const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    dashboardReducers: state.get(dashboardReducers)
});

export default connect(mapStateToProps, null)(Dashboard);