import { Button } from '@material-ui/core';
import React from 'react';
// import { Checkbox } from '@material-ui/core';
import './base.scss';

interface IFormFooter {
    onCancel: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    onSubmit: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    onSubmitContinue?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const FormFooter = (props: IFormFooter) => {

    // const [select, setSelect] = React.useState<any>({});

    const { onCancel, onSubmitContinue, onSubmit } = props;

    // const onSelect = (key: number) => {
    //     props.selectAction(key);
    // }

    // const onSelectAll = (checked: boolean) => {
    //     props.selectAllAction(checked);
    // }

    // console.log(body);

    return (
        <div className="form__footer">
            <Button variant="contained" color="secondary" onClick={ onCancel }>
                Cancel
            </Button>
            &nbsp;&nbsp;
            <Button variant="outlined" color="primary" onClick={ onSubmitContinue }>
                Save and continue
            </Button>
            &nbsp;&nbsp;
            <Button variant="contained" color="primary" onClick={ onSubmit }>
                Save
            </Button>
        </div>
    )
};

export default FormFooter;