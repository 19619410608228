import React from 'react';
import { Paper } from '@material-ui/core';
import './base.scss';

interface messageBox {
    title: string,
    content: string
}

export const MessageBox = (props: messageBox) => (
    <Paper className="message_container error">
        <p>{props.title}</p>
        <div>
            {props.content}
        </div>
    </Paper>
);