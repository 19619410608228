export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST';
export const AUTH_LOGIN_REQUEST_SUCCESS = 'AUTH_LOGIN_REQUEST_SUCCESS';
export const AUTH_LOGIN_REQUEST_FAILED = 'AUTH_LOGIN_REQUEST_FAILED';
export const AUTH_LOGOUT_REQUEST = 'AUTH_LOGOUT_REQUEST'

export const DASHBOARD_SELECT_MENU = 'DASHBOARD_SELECT_MENU';
export const DASHBOARD_RESET = 'DASHBOARD_RESET';

export const COMPANY_GET_ALL_REQUEST = 'COMPANY_GET_ALL_REQUEST';
export const COMPANY_GET_ALL_REQUEST_SUCCESS = 'COMPANY_GET_ALL_REQUEST_SUCCESS';
export const COMPANY_GET_ALL_REQUEST_FAILED = 'COMPANY_GET_ALL_REQUEST_FAILED';
export const COMPANY_GET_REQUEST = 'COMPANY_GET_REQUEST';
export const COMPANY_GET_REQUEST_SUCCESS = 'COMPANY_GET_REQUEST_SUCCESS';
export const COMPANY_GET_REQUEST_FAILED = 'COMPANY_GET_REQUEST_FAILED';
export const COMPANY_POST_REQUEST = 'COMPANY_POST_REQUEST';
export const COMPANY_POST_REQUEST_SUCCESS = 'COMPANY_POST_REQUEST_SUCCESS';
export const COMPANY_POST_REQUEST_FAILED = 'COMPANY_POST_REQUEST_FAILED';
export const COMPANY_PUT_REQUEST = 'COMPANY_PUT_REQUEST';
export const COMPANY_PUT_REQUEST_SUCCESS = 'COMPANY_PUT_REQUEST_SUCCESS';
export const COMPANY_PUT_REQUEST_FAILED = 'COMPANY_PUT_REQUEST_FAILED';
export const COMPANY_DELETE_REQUEST = 'COMPANY_DELETE_REQUEST';
export const COMPANY_DELETE_REQUEST_SUCCESS = 'COMPANY_DELETE_REQUEST_SUCCESS';
export const COMPANY_DELETE_REQUEST_FAILED = 'COMPANY_DELETE_REQUEST_FAILED';
export const COMPANY_POST_UPLOAD_REQUEST = 'COMPANY_POST_UPLOAD_REQUEST';
export const COMPANY_POST_UPLOAD_REQUEST_SUCCESS = 'COMPANY_POST_UPLOAD_REQUEST_SUCCESS';
export const COMPANY_POST_UPLOAD_REQUEST_FAILED = 'COMPANY_POST_UPLOAD_REQUEST_FAILED';
export const COMPANY_LIST_SELECT_ITEM = 'COMPANY_LIST_SELECT_ITEM';
export const COMPANY_LIST_SELECT_ALL_ITEM = 'COMPANY_LIST_SELECT_ALL_ITEM';
export const COMPANY_ACTION_FAILED = 'COMPANY_ACTION_FAILED';

export const REGION_GET_ALL_REQUEST = 'REGION_GET_ALL_REQUEST';
export const REGION_GET_ALL_REQUEST_SUCCESS = 'REGION_GET_ALL_REQUEST_SUCCESS';
export const REGION_GET_ALL_REQUEST_FAILED = 'REGION_GET_ALL_REQUEST_FAILED';

export const QUIZ_GET_ALL_REQUEST = 'QUIZ_GET_ALL_REQUEST';
export const QUIZ_GET_ALL_REQUEST_SUCCESS = 'QUIZ_GET_ALL_REQUEST_SUCCESS';
export const QUIZ_GET_ALL_REQUEST_FAILED = 'QUIZ_GET_ALL_REQUEST_FAILED';
export const QUIZ_EXPORT_REQUEST = 'QUIZ_EXPORT_REQUEST';
export const QUIZ_EXPORT_REQUEST_SUCCESS = 'QUIZ_EXPORT_REQUEST_SUCCESS';
export const QUIZ_EXPORT_REQUEST_FAILED = 'QUIZ_EXPORT_REQUEST_FAILED';
export const QUIZ_DELETE_REQUEST = 'QUIZ_DELETE_REQUEST';
export const QUIZ_DELETE_REQUEST_SUCCESS = 'QUIZ_DELETE_REQUEST_SUCCESS';
export const QUIZ_DELETE_REQUEST_FAILED = 'QUIZ_DELETE_REQUEST_FAILED';
export const QUIZ_LIST_SELECT_ITEM = 'QUIZ_LIST_SELECT_ITEM';
export const QUIZ_LIST_SELECT_ALL_ITEM = 'QUIZ_LIST_SELECT_ALL_ITEM';
export const QUIZ_ACTION_FAILED = 'QUIZ_ACTION_FAILED';