import React from 'react';
import './templates.scss';

class Outer extends React.Component {
    render() {
        return (
            <div className="outer__main">
                { this.props.children }
            </div>
        )
    }
};

export default Outer;