const stringEmptyValidation = (value: string) => {
    if (value === '' || value === null || value === undefined) return false;
    return true;
}

export const genericValidation = (fields: any) => {
    const _fields = [];
    for (const key in fields) {
        if (typeof (fields[key]) === 'string') {
            if(!stringEmptyValidation(fields[key])) { _fields.push(key); continue; };
        }
    }
    return _fields;
}

// export const passwordValidation = (password: string) => {
//     if (password.length < i)
// }