import React from 'react';
import TextField from '@material-ui/core/TextField/TextField';
import { Backdrop, Button, Checkbox, CircularProgress, createStyles, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, Link, OutlinedInput, Paper, Theme, Typography, withStyles } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { connect } from 'react-redux';
import { authLoginRequest } from 'redux/actions/hmActions';
import { genericValidation } from 'utils/validation';
import { bindActionCreators, Dispatch } from 'redux';
import { MessageBox } from 'components/base/messageBox';
// import logo from './../../../assets/images/hmErpLogo1.png';
import './auth.scss';

const useStyles = (theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  });

class Login extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            company: process.env.HM_CLIENT_COMPANY,
            username: '',
            password: '',
            usernameError: false,
            passwordError: false,
            showPassword: false
        }
    }

    onChange = (value: Object) => {
        const key = Object.keys(value)[0];
        this.setState((state: any) => ({
            ...state,
            ...value,
            [`${key}Error`]: false
        }));
    }

    onSubmit = () => {
        const { username, password } = this.state;
        const fields = genericValidation({username, password});
        if (fields.length > 0) {
            for (const item of fields) {
                this.setState((state: any) => ({
                    ...state,
                    [`${item}Error`]: true
                }));
            }
            return;
        }
        this.props.authLoginRequest({username, password});
    }

    render() {
        const { loading, failed, failedTitle, failedContent } = this.props.hmReducers;
        const { username, password, showPassword, usernameError, passwordError } = this.state;
        const { classes } = this.props;

        return (
            <div className='login__container'>
                <div className='form__container'>
                    {/* <div className='logo'>
                        <img src={logo} />
                    </div> */}
                    <div className='title'>
                        <p>Login</p>
                    </div>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <div className='form'>
                        <form noValidate autoComplete="off">
                            <TextField id="username"
                                error={ usernameError }
                                label="User" 
                                variant="outlined"
                                fullWidth={true}
                                value={username}
                                helperText={ usernameError ? "Not valid user" : '' }
                                onChange={(e) => this.onChange({username: e.target.value})}
                            />
                            <div className="breaker"/>
                            {/* <TextField id="outlined-basic"
                                label="Contraseña" 
                                variant="outlined"
                                fullWidth={true}
                            /> */}
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="password" error={ passwordError }>Password</InputLabel>
                                <OutlinedInput
                                    id="password"
                                    error={ passwordError }
                                    type= { showPassword ? 'text' : 'password'}
                                    endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="password visibility"
                                        onClick={ () => this.onChange({showPassword: !showPassword}) }
                                        edge="end"
                                        >
                                            { showPassword ? <Visibility /> : <VisibilityOff /> }
                                        </IconButton>
                                    </InputAdornment>
                                    }
                                    labelWidth={90}
                                    value={password}
                                    // helperText={ passwordError ? 'El password no es válido' : '' }
                                    onChange={(e) => this.onChange({password: e.target.value})}
                                />
                            </FormControl>
                            <div className="breaker"/>
                            <div className="actions">
                                <div>
                                    <FormControlLabel
                                        className='label'
                                        value="end"
                                        control={<Checkbox color="primary" />}
                                        label="Remember me"
                                        labelPlacement="end"
                                    />
                                </div>
                                <div>
                                    <Typography className='typography'>
                                        <Link href="#" color="inherit">
                                        Forgot your password
                                        </Link>
                                    </Typography>
                                </div>
                            </div>
                            <div className="breaker"/>
                            <div className="breaker"/>
                            <div className="breaker"/>
                            <div className="button">
                                <Button variant="contained" color="primary" fullWidth 
                                    onClick={this.onSubmit}
                                    endIcon={ <i className="fas fa-arrow-right"></i> } >
                                    Continue
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
                {/* <div className='welcome__container'>
                    <div>
                        <div className='welcome'>
                            <h1>Bienvenido a <b/>{this.state.company}</h1>
                            <h5>Por favor inicie sesión para continuar</h5>
                        </div>
                        <div className='lenguage'>

                        </div>
                        <div className='info'>
                            <a href='https://www.hmerp.com' target='_blank'>www.hmerp.com</a>
                        </div>
                    </div>
                </div> */}
                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        )
    }
};

const reducer = 'hmReducers';

const mapStateToProps = (state: any) => ({
    hmReducers: state.get(reducer)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        authLoginRequest,
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(Login));