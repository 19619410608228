import React, { StatelessComponent } from 'react';

const EditableCell : StatelessComponent<any> = ({header, data}) => {
    return (
        <>
            <table className="grid__table">
                <thead>
                    <tr>
                        {
                            header.map((item: any, i: any) => (
                                item === '' &&
                                <th style={ { width: '3em' } } key={ i }>{ item }</th> ||
                                <th key={ i }>{ item }</th>
                            ))
                        }
                        {/* <th>Codigo</th>
                        <th>Descripcion</th> */}
                    </tr>
                </thead>
                <tbody>
                    { data }
                </tbody>
            </table>
        </>
    )
}

export default EditableCell;