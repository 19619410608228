import React from 'react';
import { Backdrop, ButtonGroup, Checkbox, CircularProgress, createStyles, Paper, TextField, Theme, Tooltip, withStyles } from '@material-ui/core';
import ListView from 'hm-components/base/listView';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { companyGetAllRequest, companyListSelectItem, companyListSelectAllItem, companyActionFailed, companyDeleteRequest, companyPostUploadRequest } from 'hm-redux/actions/hmActions';
import history from '../../../utils/history';
import './company.scss'
import filePNG from './../../../../icons/fluent/file.png';
import fileEditPNG from './../../../../icons/fluent/file-edit.png';
import fileDeletePNG from './../../../../icons/fluent/delete.png';
import fileCopyPNG from './../../../../icons/fluent/copy.png';
import fileDownloadPNG from './../../../../icons/fluent/download.png';
import fileUploadPNG from './../../../../icons/fluent/upload.png';
import { MessageBox } from 'hm-components/base/messageBox';
import ActionDialog from 'hm-components/base/dialog';
import { Input } from 'hm-components/base/input';
import Button from 'hm-components/base/button';

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    });

const header = [
    { title: 'Id', key: 'id' },
    { title: 'Name', key: 'name' },
    { title: 'Contries', key: '_region', value: (call, item) => { const val = call(item, 'region') || []; return JSON.stringify(val.map(x => {return x.name})); } },
    { title: 'Sites', key: '_branch', value: (call, item) => { const val = call(item, 'branch') || []; return JSON.stringify(val.map(x => {return x.name})); } },
    { title: 'Quiz URL', key: 'quizurl' },
]

class ListUsers extends React.Component<any, any> {
// (173/20).toFixed(0) = 9
    constructor(props: any) {
        super(props);
        this.state = {
            openModal: false,
            searchValue: '',
            skip: 0, // (pagina - 1) *20
            take: 100,
            currentPage: 1
        }
    }

    componentDidMount() {
        const { companyGetAllRequest } = this.props;
        companyGetAllRequest(); 
    }

    agregar = () => {
        history.push('/admin/company/add');
    }

    editar = () => {
        const select = this.props.hmReducers.list.find((x: any) => x.select);
        if (select !== undefined)
            history.push('/admin/company/edit/' + select.id);
        else
            this.props.companyActionFailed('Error: 0x0', 'No se ha selecionado un elemento para modificar');
    }

    eliminar = () => {
        const select = this.props.hmReducers.list.filter((x: any) => x.select).map((x: any) => {
            return x.id
        })
        this.props.companyDeleteRequest(select);
        this.handleCloseModal();
    }

    importExcel = (e: any) => {
        const { companyPostUploadRequest } = this.props;
        if(confirm("Upload File: " + e.target.files[0].name))
        companyPostUploadRequest(e.target.files[0]); 
    }

    handleClickOpenModal = () => {
        // setAnchorEl(null);
        this.setState({
            openModal: true
        });
    };

    handleCloseModal = () => {
        this.setState({
            openModal: false
        });
    };

    onChangeRoot = (value) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onSearch = (e) => {
        const { companyGetAllRequest } = this.props;
        companyGetAllRequest(); 
    }

    onNavigate = (page: number) => {
        this.setState({
            currentPage: page,
            skip: (page - 1) * 100
        });
    }

    render() {
        const { searchValue, currentPage } = this.state;
        const { loading, list, total, failed, failedTitle, failedContent  } = this.props.hmReducers;
        const { classes } = this.props;

        return (
            <div>
                {/* <Paper className='list__container'>
                    <div className='search__container'>
                        <div className='search__box'>
                            <Input id="search" type="text" value={searchValue} onChange={ (e: any) => this.onChangeRoot({'searchValue': e.target.value}) }/>
                        </div>
                        <Button size="small" type="primary" onClick={this.onSearch}>Search</Button>
                    </div>
                </Paper> */}
                <br/>
                { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                <Paper className='list__container'>
                    <div className='tool__container'>
                        <Tooltip title="Add" aria-label="add">
                            <button><img width={25} height={25} src={ filePNG } onClick={() => this.agregar()}/></button>
                        </Tooltip>
                        <Tooltip title="Edit" aria-label="edit">
                            <button><img width={25} height={25} src={ fileEditPNG } onClick={() => this.editar() }/></button>
                        </Tooltip>
                        <Tooltip title="Delete" aria-label="delete">
                            <button><img width={25} height={25} src={ fileDeletePNG } onClick={() => this.handleClickOpenModal() }/></button>
                        </Tooltip>
                        <div style={{ margin: '7px 0px', marginRight: '5px', width: '2px', backgroundColor: '#cecdcd' }}/>
                        <input
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            style={{display: 'none'}}
                            id="contained-button-file"
                            type="file"
                            onChange={this.importExcel}
                        />
                        <Tooltip title="Upload" aria-label="upload">
                            <label htmlFor="contained-button-file" style={{verticalAlign: 'middle', textAlign: 'center'}}><span><img style={{marginTop: '5px'}} width={25} height={25} src={ fileUploadPNG }/></span></label>
                        </Tooltip>
                        <Tooltip title="Download" aria-label="download">
                            <button><img width={25} height={25} src={ fileDownloadPNG }/></button>
                        </Tooltip>
                        {/* <ButtonGroup color="default" variant='text' aria-label="outlined primary button group">
                            <Button endIcon={ <i className="fas fa-plus" style= {{ color: '#005180'}}></i> }>Agregar</Button>
                            <Button endIcon={ <i className="fas fa-edit" style= {{ color: '#1fa591'}}></i> }>Editar</Button>
                            <Button endIcon={ <i className="fas fa-trash" style= {{ color: '#d40303'}}></i> }>Eliminar</Button>
                        </ButtonGroup> */}
                    </div>
                    <ListView
                        header={header} body={list} 
                        selectAction={this.props.companyListSelectItem} selectAllAction={this.props.companyListSelectAllItem} 
                        currentPage={currentPage} total={total}
                        onNavigate={this.onNavigate}/>
                    <br/>
                </Paper>
               <ActionDialog handleClose={this.handleCloseModal} open={this.state.openModal} onSubmit={this.eliminar}/>
                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        );
    }
}

const reducer = 'hmReducers';

const mapStateToProps = (state: any) => ({
    hmReducers: state.get(reducer)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        companyGetAllRequest,
        companyListSelectItem,
        companyListSelectAllItem,
        companyActionFailed,
        companyDeleteRequest,
        companyPostUploadRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(ListUsers));