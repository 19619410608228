import React from 'react';
import clsx from 'clsx';
import { createStyles, Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, makeStyles, Theme, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import history from '../../utils/history';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      backgroundColor: '#ffffff',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(5,5) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    }
  }),
);

const DrawerDashboard: React.FunctionComponent<any> = ({open, hmReducers}: any) => {
    const classes = useStyles();
    const menu = hmReducers.tables.menu || [];

    const onNavigateMenu = (menuurls: string) => {
        if(menuurls !== '#') {
            history.push(menuurls);
        }
    }

    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}
        >
            <div className={classes.toolbar}>
            </div>
            <Divider />
            <List>
                {menu.map((item: any) => (
                    <ListItem button key={item.moducode} style={{ paddingLeft: '0px' }} onClick={() => onNavigateMenu(item.menuurls)}>
                        <ListItemIcon style={{ minWidth: '60px' }}><i style={{ margin: 'auto' }} className={item.menuicon}></i></ListItemIcon>
                        <ListItemText >
                          <Typography style={{ fontSize: '.8rem'}} >{item.moduname}</Typography>
                        </ListItemText>
                    </ListItem>
                ))}
            </List>
        </Drawer>
    )
}

const hmReducers = 'hmReducers';

const mapStateToProps = (state: any) => ({
  hmReducers: state.get(hmReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
      // dashboardSelectMenu,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(DrawerDashboard);