import * as usersPipeline from './pipelines/usersPipeline';
import * as financePipeline from './pipelines/financePipeline';
import * as hmPipeline from './pipelines/hmPipeline';

const pipelines = {
    usersPipeline,
    financePipeline,
    hmPipeline
};

export default pipelines;