import axios from 'axios';
import { baseModels } from 'hm-models';

const headers = () => {
    return {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    }
};

const request: baseModels.hmRequest = { } as baseModels.hmRequest;

request.postWithoutToken = (route: string, payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(route, payload)
        .then((res: any) => resolve(res))
        .catch((err: any) => reject(err))
    });
}

request.postWithToken = (route: string, payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(route, payload, headers())
        .then((res: any) => resolve(res))
        .catch((err: any) => reject(err))
    });
}

request.postUploadWithToken = (route: string, payload: any, header) => {
    // const _header = headers();
    // _header.headers = { ..._header.headers, ...header };
    let _header = headers();
    _header = { ..._header, ...header }
    return new Promise((resolve, reject) => {
        axios.post(route, payload, _header)
        .then((res: any) => resolve(res))
        .catch((err: any) => reject(err))
    });
}

request.getWithoutToken = (route: string) => {
    return new Promise((resolve, reject) => {
        axios.get(route)
        .then((res: any) => resolve(res))
        .catch((err: any) => reject(err))
    });
}

request.getWithToken = (route: string) => {
    return new Promise((resolve, reject) => {
        axios.get(route, headers())
        .then((res: any) => resolve(res))
        .catch((err: any) => reject(err))
    });
}

request.putWithToken = (route: string, payload: any) => {
    return new Promise((resolve, reject) => {
        axios.put(route, payload, headers())
        .then((res: any) => resolve(res))
        .catch((err: any) => reject(err))
    });
}

request.deleteWithToken = (route: string) => {
    return new Promise((resolve, reject) => {
        axios.delete(route, headers())
        .then((res: any) => resolve(res))
        .catch((err: any) => reject(err))
    });
}

request.postExportWithToken = (route: string, payload: any, header) => {
    let _header = headers();
    _header = { ..._header, ...header }
    return new Promise((resolve, reject) => {
        axios.post(route, payload, _header)
        .then((res: any) => resolve(res))
        .catch((err: any) => reject(err))
    });
}

export default request;