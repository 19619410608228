import React from 'react';
import Loadable from 'react-loadable';

export const LoginPage = Loadable({
    loader: () => import('./auth/login'),
    loading: () => { return <div>loading...</div> }
});

export const DashboardPage = Loadable({
    loader: () => import('./dashboard/dashboard'),
    loading: () => { return <div>loading...</div> }
});

export const ListCompany = Loadable({
    loader: () => import('./company/listCompany'),
    loading: () => { return <div>loading...</div> }
});

export const FormCompany = Loadable({
    loader: () => import('./company/formCompany'),
    loading: () => { return <div>loading...</div> }
});

export const ListQuiz = Loadable({
    loader: () => import('./quiz/listQuiz'),
    loading: () => { return <div>loading...</div> }
});