import { 
    AUTH_LOGIN_REQUEST, 
    AUTH_LOGIN_REQUEST_FAILED, 
    AUTH_LOGIN_REQUEST_SUCCESS, 
    AUTH_LOGOUT_REQUEST, 
    COMPANY_ACTION_FAILED, 
    COMPANY_DELETE_REQUEST, 
    COMPANY_DELETE_REQUEST_FAILED, 
    COMPANY_DELETE_REQUEST_SUCCESS, 
    COMPANY_GET_ALL_REQUEST, 
    COMPANY_GET_ALL_REQUEST_FAILED, 
    COMPANY_GET_ALL_REQUEST_SUCCESS, 
    COMPANY_GET_REQUEST, 
    COMPANY_GET_REQUEST_FAILED, 
    COMPANY_GET_REQUEST_SUCCESS, 
    COMPANY_LIST_SELECT_ALL_ITEM, 
    COMPANY_LIST_SELECT_ITEM, 
    COMPANY_POST_REQUEST, 
    COMPANY_POST_REQUEST_FAILED, 
    COMPANY_POST_REQUEST_SUCCESS, 
    COMPANY_POST_UPLOAD_REQUEST, 
    COMPANY_POST_UPLOAD_REQUEST_FAILED, 
    COMPANY_POST_UPLOAD_REQUEST_SUCCESS, 
    COMPANY_PUT_REQUEST, 
    COMPANY_PUT_REQUEST_FAILED, 
    COMPANY_PUT_REQUEST_SUCCESS, 
    DASHBOARD_RESET, 
    DASHBOARD_SELECT_MENU, 
    QUIZ_ACTION_FAILED, 
    QUIZ_DELETE_REQUEST, 
    QUIZ_DELETE_REQUEST_FAILED, 
    QUIZ_DELETE_REQUEST_SUCCESS, 
    QUIZ_EXPORT_REQUEST, 
    QUIZ_EXPORT_REQUEST_FAILED, 
    QUIZ_EXPORT_REQUEST_SUCCESS, 
    QUIZ_GET_ALL_REQUEST, 
    QUIZ_GET_ALL_REQUEST_FAILED, 
    QUIZ_GET_ALL_REQUEST_SUCCESS, 
    QUIZ_LIST_SELECT_ALL_ITEM, 
    QUIZ_LIST_SELECT_ITEM, 
    REGION_GET_ALL_REQUEST,
    REGION_GET_ALL_REQUEST_FAILED,
    REGION_GET_ALL_REQUEST_SUCCESS
} from '../constants/hmConstants';

const initialState: any = {
    loggedIn: false,
    token: localStorage.getItem('token') || '',
    user: JSON.parse(localStorage.getItem('user')) || null,
    tables: {
        menu:[
            {
                "moducode":"COMPA",
                "moduname":"Companies",
                "menuicon":"fas fa-building",
                "menuurls":"/admin/company"
            },
            {
                "moducode":"QUIZS",
                "moduname":"Responses",
                "menuicon":"fas fa-file",
                "menuurls":"/admin/quiz"
            }
        ],
        region: []
    },
    onlyOne: null,
    list: [],
    total: 0,
};

export default function authReducer(state = initialState, action: any = {}) {
    switch(action.type) {
        case AUTH_LOGIN_REQUEST:
            return {
                ...state,
                loading: true
            };
        case AUTH_LOGIN_REQUEST_SUCCESS:
            return {
                ...state,
                loggedIn: true,
                user: JSON.parse(localStorage.getItem('user')),
                token: localStorage.getItem('token'),
                // tables: JSON.parse(localStorage.getItem('tables')),
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case AUTH_LOGIN_REQUEST_FAILED:
            return {
                ...state,
                loggedIn: false,
                token: '',
                user: null,
                // tables: null,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case AUTH_LOGOUT_REQUEST:
            return initialState;
        case DASHBOARD_SELECT_MENU:
            return {
                ...state,
                selectMenu: action.moducode
            }
        case DASHBOARD_RESET:
            return {
                ...state,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null,
                selectMenu: ''
            };
        case COMPANY_GET_ALL_REQUEST:
        case QUIZ_GET_ALL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case COMPANY_GET_ALL_REQUEST_SUCCESS:
        case QUIZ_GET_ALL_REQUEST_SUCCESS:
            return {
                ...state,
                list: action.list,
                total: action.total,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case COMPANY_GET_ALL_REQUEST_FAILED:
        case QUIZ_GET_ALL_REQUEST_FAILED:
            return {
                ...initialState,
                list: [],
                total: 0,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case COMPANY_GET_REQUEST:
        case REGION_GET_ALL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case COMPANY_GET_REQUEST_SUCCESS:
        case REGION_GET_ALL_REQUEST_SUCCESS:
            return {
                ...state,
                tables: { ...state.tables, ...action.tables },
                onlyOne: action.onlyOne,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case COMPANY_GET_REQUEST_FAILED:
        case REGION_GET_ALL_REQUEST_FAILED:
            return {
                ...initialState,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case COMPANY_POST_REQUEST:
            return {
                ...state,
                loading: true
            };
        case COMPANY_POST_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case COMPANY_POST_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case COMPANY_PUT_REQUEST:
            return {
                ...state,
                loading: true
            };
        case COMPANY_PUT_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case COMPANY_PUT_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case COMPANY_DELETE_REQUEST:
        case QUIZ_DELETE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case COMPANY_DELETE_REQUEST_SUCCESS:
        case QUIZ_DELETE_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case COMPANY_DELETE_REQUEST_FAILED:
        case QUIZ_DELETE_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case COMPANY_POST_UPLOAD_REQUEST:
        case QUIZ_EXPORT_REQUEST:
            return {
                ...state,
                loading: true
            };
        case COMPANY_POST_UPLOAD_REQUEST_SUCCESS:
        case QUIZ_EXPORT_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case COMPANY_POST_UPLOAD_REQUEST_FAILED:
        case QUIZ_EXPORT_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case COMPANY_LIST_SELECT_ITEM:
        case QUIZ_LIST_SELECT_ITEM:
            const newList = state.list;
            newList[action.key].select = !newList[action.key].select;
            return {
                ...state,
                list: newList
            };
        case COMPANY_LIST_SELECT_ALL_ITEM:
        case QUIZ_LIST_SELECT_ALL_ITEM:
            const newListAll = state.list;
            for (const item of newListAll) {
                item.select = action.checked
            }
            return {
                ...state,
                list: newListAll
            };
        case COMPANY_ACTION_FAILED:
        case QUIZ_ACTION_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        default:
            return state;
    }
}