import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Dashboard from '../templates/dashboard';
import {
  DashboardPage,
  ListCompany,
  FormCompany,
  ListQuiz
} from '../pages/pageListAsync';
import { PrivateRoute } from 'hm-components/base/privateRoute';

class Application extends React.Component {
  render() {
    return (
      <Dashboard>
        <Switch>
          <PrivateRoute exact path="/admin" component={DashboardPage} />
          <PrivateRoute path="/admin/company/edit/:id" component={FormCompany} />
          <PrivateRoute path="/admin/company/add" component={FormCompany} /> 
          <PrivateRoute path="/admin/company" component={ListCompany} />
          <PrivateRoute path="/admin/quiz" component={ListQuiz} />
        </Switch>
      </Dashboard>
    );
  }
}

export default Application;